import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Image } from 'grommet';

import { optimizeImageSrc } from '@/helpers/util';
import { ApproachButton } from '../ContentSections/ContentSections';
import {
  StyledIllustrationWrapper, StyledHeader, StyledSubHeader, StyledPreHeader,
} from './StyledHeadSection';

const HeadSection = ({
  small,
  fullHeightHeadSection = true,
  preHeader = null,
  preHeaderColor = null,
  header,
  headerColor,
  desktopHeader = null,
  desktopHeaderLogo = null,
  subHeader = null,
  headersBg = null,
  image = null,
  fullPageBgImage = null,
  fullPageBgImageMobile = null,
  customBgColor,
  headerApproach = null,
}) => {
  const altLayout = desktopHeader && desktopHeaderLogo;
  const sectionHeight = altLayout ? '50vh' : '75vh';
  const desktopHeaderWidth = (altLayout || headersBg) ? '100%' : '45%';
  const leftPad = small ? 'var(--vw5p725)' : 'var(--vw7p725)';
  let rightPad = small ? 'var(--vw5p725)' : 'var(--vw7p725)';
  const topMargin = (headersBg && small) ? 'calc(var(--vw31) + var(--vw31) + 10vh + 3rem)' : '0';
  let topPad = altLayout ? '5rem' : 'calc(var(--vw31) + var(--vw31) + 1.5rem)';
  const innerBottomPad = headersBg ? '2rem' : 'calc(40px + var(--vw4))';
  const innerTopPad = headersBg ? '2rem' : '0';

  const renderHeader = () => {
    if (altLayout && !small) {
      return (
        <Box direction="row" align="center" justify="center" width="100%" height="5rem" gap="1.5rem">
          <StyledHeader level={1} margin="none" color={headerColor} textAlign="start">
            {desktopHeader}
          </StyledHeader>
          <Box align="center" height="100%" width={{ max: '18rem' }}>
            <Image
              fit="contain"
              src={optimizeImageSrc(desktopHeaderLogo)}
              alt="Redflag AI logo"
              height="100%"
              width={{ max: '18rem' }}
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box width={(small || headersBg) ? '100%' : '47%'}>
        <StyledHeader level={1} margin="none" color={headerColor} textAlign={(altLayout && small) ? 'center' : 'start'}>
          {header}
        </StyledHeader>
      </Box>
    );
  };


  if (headersBg) {
    rightPad = small ? 'var(--vw5p725)' : '3.5rem';
    topPad = '0';
  }

  return (
    <Box
      overflow="hidden"
      background={{
        image: small ? `url(${optimizeImageSrc(fullPageBgImageMobile)})` : `url(${optimizeImageSrc(fullPageBgImage)})`,
        color: customBgColor,
      }}
      pad={{ top: '5.375rem' }}
    >
      <StyledIllustrationWrapper
        fill
        fullHeight={fullHeightHeadSection}
        sectionHeight={sectionHeight}
        data-bg={image && optimizeImageSrc(image)}
        background={image && { image: small ? `url(${optimizeImageSrc(image)}&w=1024)` : `url(${optimizeImageSrc(image)})` }}
        justify={small ? null : 'center'}
      >
        <Box
          flex
          justify="center"
          gap="0.75rem"
          pad={{
            top: small ? innerTopPad : '0',
            bottom: small ? innerBottomPad : '0',
            left: leftPad,
            right: rightPad,
          }}
          background={headersBg || 'rgba(0,0,0,0)'}
          margin={{ right: (!small && headersBg) ? 'var(--vw55)' : '0', top: topMargin }}
        >
          <Box direction="column" margin={small ? { top: topPad } : { bottom: '-1.15rem' }}>
            {preHeader && (
              <Box pad={{ bottom: '0.75rem' }}>
                <StyledPreHeader color={preHeaderColor || headerColor} textAlign="start">
                  {preHeader}
                </StyledPreHeader>
              </Box>
            )}
            {renderHeader()}
          </Box>
          {subHeader && (
            <Box direction="column" width={small ? '100%' : desktopHeaderWidth} pad={{ top: small ? '0.75rem' : '1.5rem' }}>
              {subHeader.map((dt) => (
                <StyledSubHeader
                  key={uniqid()}
                  level={2}
                  color={headerColor}
                  margin="none"
                  textAlign={altLayout ? 'center' : 'start'}
                  weight={altLayout ? 600 : 'normal'}
                >
                  {dt}
                </StyledSubHeader>
              ))}
            </Box>
          )}
          {headerApproach && (
            <Box pad={{ top: '1rem' }} align={altLayout ? 'center' : 'start'}>
              <ApproachButton approach={headerApproach} />
            </Box>
          )}
        </Box>
      </StyledIllustrationWrapper>
    </Box>
  );
};

HeadSection.propTypes = {
  small: PropTypes.bool.isRequired,
  preHeader: PropTypes.string,
  preHeaderColor: PropTypes.string,
  fullHeightHeadSection: PropTypes.bool,
  header: PropTypes.string.isRequired,
  desktopHeader: PropTypes.string,
  desktopHeaderLogo: PropTypes.string,
  subHeader: PropTypes.arrayOf(PropTypes.string.isRequired),
  headerColor: PropTypes.string.isRequired,
  headersBg: PropTypes.string,
  image: PropTypes.string,
  fullPageBgImage: PropTypes.string,
  fullPageBgImageMobile: PropTypes.string,
  customBgColor: PropTypes.string.isRequired,
  headerApproach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default HeadSection;
