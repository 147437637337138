import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import {
  StyledSectionHeader,
  StyledSmallHeader,
  StyledCardImage,
  StyledGridCardContainer,
  StyledTextContent,
} from './StyledContentSections';
import { ApproachButton } from './ContentSections';


const IllustrationCardsScroller = ({
  small,
  header = null,
  altHeader = null,
  headerColor,
  headerAlign = null,
  horizontalPad = null,
  cardBg,
  boxShadow = null,
  cardTitleColor = null,
  cardTextColor,
  illustration,
  items,
  approach = null,
}) => {
  const listItemHeight = '9rem';

  return (
    <Box overflow="hidden">
      <Box direction="row" pad={(horizontalPad && !small) ? { horizontal: horizontalPad } : '0'}>
        <Box
          flex
          direction="column"
          gap="1.5rem"
          width="100%"
          justify={small ? 'center' : 'start'}
          align={small ? 'center' : 'start'}
        >
          <Box width="100%">
            {header && (
              <StyledSectionHeader level={2} weight={600} size="2rem" margin="none" color={headerColor} textAlign={small ? 'center' : (headerAlign || 'center')}>
                {header}
              </StyledSectionHeader>
            )}
            {altHeader && (
              <Box wrap direction="row" gap="0.5rem" justify={small ? 'center' : 'start'}>
                <StyledSectionHeader level={2} weight={600} margin="none" color={headerColor} textAlign={small ? 'center' : (headerAlign || 'center')}>
                  {altHeader.text}
                </StyledSectionHeader>
                <StyledSectionHeader level={2} weight={600} color={altHeader.highlightColor} margin="none" textAlign={small ? 'center' : (headerAlign || 'center')}>
                  {altHeader.highlightText}
                </StyledSectionHeader>
              </Box>
            )}
          </Box>
          {small && (
            <StyledCardImage
              data-bg={illustration}
              background={{ image: small ? `url(${illustration}&w=1024)` : `url(${illustration})` }}
              overflow="hidden"
              height="30rem"
              width="100%"
              bgSize="80%"
            />
          )}
          <Box direction="column" align="start">
            {items.map(({ title, text }) => (
              <Box
                key={uniqid()}
                round="10px"
                height={{ min: listItemHeight }}
              >
                <StyledGridCardContainer
                  direction="column"
                  gap="1.25rem"
                  cardBg={cardBg}
                  boxShadow={boxShadow}
                  align={small ? 'center' : 'start'}
                  justify={small ? 'evenly' : 'between'}
                  pad={{ vertical: '1.25rem' }}
                >
                  <Box
                    direction="column"
                    gap="0.75rem"
                    width="100%"
                    height="100%"
                    alignItems="start"
                    justify="start"
                  >
                    <StyledSmallHeader
                      level={3}
                      weight={600}
                      size={small ? '1.4rem' : '1.25rem'}
                      color={cardTitleColor || cardTextColor}
                      textAlign="start"
                    >
                      {title}
                    </StyledSmallHeader>
                    <Box justify="center" height="100%" align="center">
                      <StyledTextContent textAlign="start" color={cardTextColor}>{text}</StyledTextContent>
                    </Box>
                  </Box>
                </StyledGridCardContainer>
              </Box>
            ))}
            {small && approach && (
              <Box align="center" width="100%">
                <ApproachButton approach={approach} />
              </Box>
            )}
          </Box>
        </Box>
        {!small && (
          <Box flex>
            <StyledCardImage
              justify="end"
              data-bg={illustration}
              background={{ image: small ? `url(${illustration}&w=1024)` : `url(${illustration})` }}
              overflow="hidden"
              height="100%"
              width="100%"
              bgSize="85%"
            >
              {approach && (
                <Box align="center" width="100%" pad={{ bottom: '3rem' }}>
                  <ApproachButton approach={approach} />
                </Box>
              )}
            </StyledCardImage>
          </Box>
        )}
      </Box>
    </Box>
  );
};

IllustrationCardsScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string,
  altHeader: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    highlightColor: PropTypes.string.isRequired,
  }),
  headerAlign: PropTypes.string,
  headerColor: PropTypes.string.isRequired,
  horizontalPad: PropTypes.string,
  cardTitleColor: PropTypes.string,
  cardTextColor: PropTypes.string.isRequired,
  cardBg: PropTypes.string.isRequired,
  boxShadow: PropTypes.string,
  illustration: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default IllustrationCardsScroller;
