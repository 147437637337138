import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { paths } from '@/components/configs';
import { optimizeImageSrc } from '@/helpers/util';
import {
  ApproachButton, ScrollerHeader, renderListText,
} from './ContentSections';
import {
  StyledGridCardContainer,
  StyledCardWrapper,
  StyledGridCardAnchorContainer,
  StyledTextContent,
  StyledScrollerSubHeader,
  StyledSmallHeader,
  StyledSVG,
} from './StyledContentSections';


const CardContainer = ({
  isPlain,
  cardBg = null,
  industryKey = null,
  boxShadow = null,
  hoverColor = null,
  itemAlign = 'center',
  children,
}) => {
  if (hoverColor && industryKey) {
    return (
      <StyledGridCardAnchorContainer
        path={paths.industries.replace(':uid', industryKey)}
        cardBg={isPlain ? null : cardBg}
        boxShadow={isPlain ? 'none' : boxShadow}
        hoverColor={hoverColor}
      >
        <Box
          direction="column"
          align={itemAlign}
          justify={itemAlign}
          pad={isPlain ? '1rem' : '1.5rem'}
        >
          {children}
        </Box>
      </StyledGridCardAnchorContainer>
    );
  }

  return (
    <StyledGridCardContainer
      direction="column"
      cardBg={isPlain ? null : cardBg}
      boxShadow={isPlain ? 'none' : boxShadow}
      align={itemAlign}
      justify={itemAlign}
      pad={isPlain ? '1rem' : '1.5rem'}
      height="100%"
    >
      {children}
    </StyledGridCardContainer>
  );
};

CardContainer.propTypes = {
  isPlain: PropTypes.bool.isRequired,
  cardBg: PropTypes.string,
  boxShadow: PropTypes.string,
  hoverColor: PropTypes.string,
  industryKey: PropTypes.string,
  itemAlign: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Cards = ({
  small,
  isPlain,
  withList,
  boxShadow = null,
  hoverColor = null,
  cardBg = null,
  cardTextColor,
  cardHeaderColor = null,
  cardWidth = null,
  mobileCardWidth = null,
  itemAlign = 'center',
  items,
  ...rest
}) => {
  const cardWidthPercentage = 100 / items.length;
  const useCardWidth = cardWidth ?? `calc(${cardWidthPercentage}% - 3rem)`;
  let useMobileCardWidth = mobileCardWidth ?? null;

  if (small && isPlain) useMobileCardWidth = '100%';

  return (
    <Box
      flex
      wrap
      justify="center"
      direction={(small && isPlain) ? 'column' : 'row'}
    >
      {items.map(({
        icon, header, text, list, industryKey,
      }) => (
        <StyledCardWrapper
          key={uniqid()}
          round="10px"
          cardWidth={useCardWidth}
          mobileCardWidth={useMobileCardWidth}
          height={{ min: '9rem' }}
          margin={(small && isPlain) ? '0rem' : '1rem'}
        >
          <CardContainer
            small={small}
            isPlain={isPlain}
            cardBg={cardBg}
            boxShadow={boxShadow}
            hoverColor={hoverColor}
            icon={icon}
            industryKey={industryKey}
            itemAlign={itemAlign}
          >
            {icon && (
              <Box width="100%" height="100%" align="center" justify={itemAlign}>
                <Box width={small ? '4rem' : '3rem'} height={small ? '4rem' : '3rem'}>
                  <StyledSVG
                    src={small ? `${optimizeImageSrc(icon)}&w=350` : optimizeImageSrc(icon)}
                    width={small ? '4rem' : '3rem'}
                    height={small ? '4rem' : '3rem'}
                    title={`${header} icon`}
                  />
                </Box>
              </Box>
            )}
            <Box
              direction="column"
              gap={isPlain ? '0.5rem' : '1rem'}
              width="100%"
              height="100%"
              alignItems={itemAlign}
              justify={small ? 'center' : 'end'}
              pad={icon ? { top: '1rem' } : '0rem'}
            >
              {header && (
                <StyledSmallHeader
                  level={3}
                  weight={600}
                  size={small ? '1.4rem' : '1.25rem'}
                  color={cardHeaderColor || cardTextColor}
                  textAlign={itemAlign}
                >
                  {header}
                </StyledSmallHeader>
              )}
              {(list || text) && (
                <Box justify={isPlain ? 'start' : 'center'} height="100%" align="center">
                  {withList && Array.isArray(list)
                    ? renderListText(small, list, cardTextColor, rest.highlightColor)
                    : (
                      <StyledTextContent
                        textAlign={itemAlign}
                        color={cardTextColor}
                      >
                        {text}
                      </StyledTextContent>
                    )}
                </Box>
              )}
            </Box>
          </CardContainer>
        </StyledCardWrapper>
      ))}
    </Box>
  );
};

Cards.propTypes = {
  small: PropTypes.bool.isRequired,
  isPlain: PropTypes.bool.isRequired,
  withList: PropTypes.bool.isRequired,
  cardBg: PropTypes.string,
  cardWidth: PropTypes.string,
  mobileCardWidth: PropTypes.string,
  itemAlign: PropTypes.string,
  boxShadow: PropTypes.string,
  hoverColor: PropTypes.string,
  cardTextColor: PropTypes.string.isRequired,
  cardHeaderColor: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
    industryKey: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string.isRequired),
  })).isRequired,
};

const CardsScroller = ({
  small,
  withList = false,
  isPlain = false,
  title,
  subTitle = null,
  titleColor,
  cardBg = null,
  boxShadow = null,
  hoverColor = null,
  cardTextColor,
  cardHeaderColor = null,
  cardWidth = null,
  mobileCardWidth = null,
  itemAlign = null,
  items,
  approach = null,
  ...rest
}) => (
  <Box justify="center" overflow="hidden">
    <Box direction="column" justify="between" gap={small ? '2rem' : '1rem'}>
      <Box align="center" justify="center" gap="1.5rem">
        <Box gap="0.5rem" width="100%">
          {!small && title.map((dt) => (
            <ScrollerHeader key={uniqid()} header={dt} color={titleColor} alignSelf="center" />
          ))}
          {small && (
            <ScrollerHeader key={uniqid()} header={title.join(' ')} color={titleColor} alignSelf="center" />
          )}
        </Box>
        {subTitle && (
          <Box width={small ? '100%' : { max: '75%' }}>
            <StyledScrollerSubHeader
              level={3}
              color={titleColor}
              margin="none"
              size="1rem"
              textAlign="center"
            >
              {subTitle}
            </StyledScrollerSubHeader>
          </Box>
        )}
      </Box>
      <Cards
        small={small}
        isPlain={isPlain}
        withList={withList}
        boxShadow={boxShadow}
        hoverColor={hoverColor}
        cardBg={cardBg}
        cardTextColor={cardTextColor}
        cardHeaderColor={cardHeaderColor}
        cardWidth={cardWidth}
        mobileCardWidth={mobileCardWidth}
        itemAlign={itemAlign}
        items={items}
        {...rest}
      />
      {approach && (
        <Box align="center" width="100%" margin={{ bottom: '1rem' }}>
          <ApproachButton approach={approach} />
        </Box>
      )}
    </Box>
  </Box>
);

CardsScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  withList: PropTypes.bool,
  isPlain: PropTypes.bool,
  title: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  titleColor: PropTypes.string.isRequired,
  boxShadow: PropTypes.string,
  hoverColor: PropTypes.string,
  subTitle: PropTypes.string,
  cardBg: PropTypes.string,
  cardWidth: PropTypes.string,
  mobileCardWidth: PropTypes.string,
  cardTextColor: PropTypes.string.isRequired,
  cardHeaderColor: PropTypes.string,
  itemAlign: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
    industryKey: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string.isRequired),
  })).isRequired,
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default CardsScroller;
