import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { ScrollerHeader, ApproachButton } from './ContentSections';
import { StyledTextContent } from './StyledContentSections';


const CTAScroller = ({
  small, title, subTitle, textColor, approach,
}) => (
  <Box overflow="hidden">
    <Box direction="column" gap="1rem">
      <ScrollerHeader header={title} color={textColor} textAlign="center" />
      {subTitle && (
        <Box width="100%" pad={{ top: '0.5rem' }}>
          <StyledTextContent color={textColor} textAlign="center">
            {subTitle}
          </StyledTextContent>
        </Box>
      )}
      {approach && (
        <Box margin={{ top: '0.5rem' }} align="center" justify="center">
          <ApproachButton approach={approach} />
        </Box>
      )}
    </Box>
  </Box>
);

CTAScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default CTAScroller;