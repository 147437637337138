import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box, Tabs, Text } from 'grommet';
import { FormNext } from 'grommet-icons';

import { LazyResponsiveImageLoader } from '@/components/Control';
import { optimizeImageSrc } from '@/helpers/util';
import {
  StyledAspectScrollerDemo,
  StyledDemoVideo,
  StyledTabScrollerTab,
  StyledScrollerHeader,
  StyledScrollerSubHeader,
  StyledSmallHeader,
} from './StyledContentSections';
import { ApproachButton, renderListText } from './ContentSections';
import UseCaseSlider from './UseCaseSlider';


const renderDemo = (loaderImage, animation, borderColor, width = null) => {
  const videoSrc = Object.keys(animation).reduce((result, key) => {
    result[key] = animation[key];
    return result;
  }, {});

  return (
    <StyledAspectScrollerDemo overflow="hidden" width={width} borderColor={borderColor}>
      <StyledDemoVideo lazySrc={optimizeImageSrc(loaderImage)} src={optimizeImageSrc(videoSrc)} fit="contain" />
    </StyledAspectScrollerDemo>
  );
};

const TabContentRenderer = ({
  small, textColor, highlight, aspect: { uid, content },
}) => {
  switch (uid) {
    case 'demo-tab-list':
      return (
        <Box key={uniqid()} direction="row-responsive" justify="between" pad={{ vertical: small ? '2.5rem' : 'var(--vw5p725)' }}>
          {small && (
            <Box margin={{ bottom: '1.75rem' }}>
              {renderDemo(content.lazyAnimation, content.animation, 'rgba(120, 138, 247, 0.3)')}
            </Box>
          )}
          <Box direction="column" gap="1.75rem" pad={small ? null : { right: '1.75rem' }} width={small ? null : { max: '50%' }}>
            <Text textAlign="start" color={textColor} size={small ? '1.5rem' : '1.75rem'} weight={700}>
              {content.headline}
            </Text>
            {Array.isArray(content.list) && (
              renderListText(small, true, content.list, textColor)
            )}
            {content.tabApproach && (
              <Box>
                <ApproachButton approach={content.tabApproach} />
              </Box>
            )}
          </Box>
          {!small && (
            <Box>
              {renderDemo(content.lazyAnimation, content.animation, 'rgba(120, 138, 247, 0.3)', 'var(--vw35p45)')}
            </Box>
          )}
        </Box>
      );

    case 'image-text-tab':
      return (
        <Box key={uniqid()}>
          <Box direction={small ? 'column' : 'row-reverse'} justify="between" pad={{ top: '2.5rem' }}>
            {small && (
              <Box pad={{ horizontal: '1.5rem' }} margin={{ top: '1.5rem' }} justify="center" align="center">
                <Box width={{ max: '25rem' }}>
                  <LazyResponsiveImageLoader
                    src={() => (small ? `${optimizeImageSrc(content.image)}&w=450` : `${optimizeImageSrc(content.image)}&w=800`)}
                    alt={`${content.image} illustration`}
                    fit="contain"
                  />
                </Box>
              </Box>
            )}
            <Box
              direction="column"
              gap={small ? '2rem' : '1.5rem'}
              pad={small ? { horizontal: '1.5rem' } : { right: '2.5rem', top: '2.5rem' }}
              width={small ? '100%' : { max: '45%' }}
            >
              <Text textAlign={small ? 'center' : 'start'} color={textColor} size={small ? '2rem' : '2.5rem'} weight={700}>
                {content.headline}
              </Text>
              {content.text.map(({ title, body }) => (
                <Box key={uniqid()} direction="row" gap="0.5rem">
                  {!small && (
                    <Box pad={{ top: '0.2rem' }}>
                      <FormNext size="1.35rem" color={highlight} />
                    </Box>
                  )}
                  <Box direction="column" width="100%" gap="0.25rem">
                    <StyledSmallHeader textAlign={small ? 'center' : 'start'} weight={700} size="1.4rem" color={highlight}>
                      {title}
                    </StyledSmallHeader>
                    <Text textAlign={small ? 'center' : 'start'} color={textColor} size="1rem">
                      {body}
                    </Text>
                  </Box>
                </Box>
              ))}
            </Box>
            {!small && (
              <Box pad={{ horizontal: '2.5rem' }} justify="center" align="center">
                <Box width={{ max: '75%' }}>
                  <LazyResponsiveImageLoader
                    src={() => (small ? `${optimizeImageSrc(content.image)}&w=450` : `${optimizeImageSrc(content.image)}&w=800`)}
                    alt={`${content.image} illustration`}
                    fit="contain"
                  />
                </Box>
              </Box>
            )}
          </Box>
          {content.lowerSection && (
            <Box gap="1rem" pad={{ top: small ? '6rem' : '3.5rem' }}>
              <Box pad={small ? null : { horizontal: '8%' }} margin={!content.lowerSection.subHeadline && { bottom: '1.5rem' }}>
                <StyledScrollerHeader
                  level={3}
                  textAlign="center"
                  margin="none"
                  color={textColor}
                  size={small ? '1.5rem' : '2rem'}
                  weight={600}
                >
                  {content.lowerSection.headline}
                </StyledScrollerHeader>
              </Box>
              {content.lowerSection.subHeadline && (
                <Box width="100%" align="center">
                  <Box width={{ max: small ? '100%' : '50%' }}>
                    <Text textAlign="center" color={textColor} size="1rem">
                      {content.lowerSection.subHeadline}
                    </Text>
                  </Box>
                </Box>
              )}
              <UseCaseSlider
                small={small}
                textColor={textColor}
                boxShadow={content.lowerSection.boxShadow}
                content={content.lowerSection.items}
                highlight={content.lowerSection.titleHighlight || highlight}
                slideBg={content.lowerSection.cardBg}
              />
              {content.tabApproach && (
                <Box width="100%" align="center" justify="center">
                  <ApproachButton approach={content.tabApproach} />
                </Box>
              )}
            </Box>
          )}
        </Box>
      );

    default:
      return null;
  }
};

TabContentRenderer.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  aspect: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    content: PropTypes.shape({
      title: PropTypes.string,
      tabTitle: PropTypes.string,
      headline: PropTypes.string.isRequired,
      text: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
      })),
      list: PropTypes.arrayOf(PropTypes.string.isRequired),
      image: PropTypes.string,
      lazyAnimation: PropTypes.node,
      animation: PropTypes.shape({
        mp4: PropTypes.node.isRequired,
        webm: PropTypes.node.isRequired,
      }),
      tabApproach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
      lowerSection: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        subHeadline: PropTypes.string,
        titleHighlight: PropTypes.string,
        boxShadow: PropTypes.string.isRequired,
        cardBg: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })).isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

const TabScroller = ({
  small,
  header = null,
  subHeader = null,
  textColor,
  tabHighlight,
  darkBg,
  lightBg,
  items,
  ...rest
}) => (
  <Box overflow="hidden">
    <Box gap="1.5rem">
      {header && (
        <Box justify="center">
          <StyledScrollerHeader
            level={2}
            color={textColor}
            margin="none"
          >
            {header}
          </StyledScrollerHeader>
        </Box>
      )}
      {subHeader && (
        <Box justify="center">
          <StyledScrollerSubHeader
            color={textColor}
            margin="none"
            size="1.25rem"
          >
            {subHeader}
          </StyledScrollerSubHeader>
        </Box>
      )}
    </Box>
    <Tabs alignControls="center">
      {items.map((item) => (
        <StyledTabScrollerTab
          key={uniqid()}
          isSmall={small}
          title={item.content.tabTitle}
          bgColor={lightBg}
          selectedBg={darkBg}
          textColor={textColor}
        >
          <TabContentRenderer
            small={small}
            aspect={item}
            textColor={textColor}
            highlight={tabHighlight}
            {...rest}
          />
        </StyledTabScrollerTab>
      ))}
    </Tabs>
  </Box>
);

TabScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  tabHighlight: PropTypes.string.isRequired,
  darkBg: PropTypes.string.isRequired,
  lightBg: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string.isRequired,
    content: PropTypes.shape({
      title: PropTypes.string,
      tabTitle: PropTypes.string,
      headline: PropTypes.string.isRequired,
      text: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
      })),
      list: PropTypes.arrayOf(PropTypes.string.isRequired),
      image: PropTypes.string,
      lazyAnimation: PropTypes.node,
      animation: PropTypes.shape({
        mp4: PropTypes.node.isRequired,
        webm: PropTypes.node.isRequired,
      }),
      tabApproach: PropTypes.shape({
        text: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        hoverColor: PropTypes.string.isRequired,
      }),
      lowerSection: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        subHeadline: PropTypes.string,
        titleHighlight: PropTypes.string,
        boxShadow: PropTypes.string.isRequired,
        cardBg: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
          title: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        })).isRequired,
      }),
    }),
  }).isRequired).isRequired,
};

export default TabScroller;
