import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { Box } from 'grommet';

import { LazyResponsiveImageLoader } from '@/components/Control';
import { optimizeImageSrc } from '@/helpers/util';
import {
  ApproachButton, ScrollerHeader, renderListText,
} from './ContentSections';

import {
  StyledTextContent,
  StyledSmallHeader,
  StyledAnalysisCardWrapper,
  StyledSVG,
} from './StyledContentSections';


const renderIconText = (
  small,
  index,
  iconAlign,
  textAlign,
  desktopWidth,
  item,
  textColor,
  titleColor,
  direction,
  rowIconTitle,
) => {
  const {
    icon, title, text, list,
  } = item;
  const innerBoxWidth = small ? '25rem' : '20rem';
  let verticalMargin = small ? '1.5rem' : '1.25rem';
  let mobileDirection = 'column';
  let titleGap = '0.75rem';
  let boxHeight = small ? '0rem' : '10rem';
  const topPad = (direction === 'row') ? '0rem' : '0.75rem';
  let smallTopPad = '0rem';

  if (direction === 'row' && !small) {
    verticalMargin = '0rem';
  }

  if (iconAlign === 'start') {
    mobileDirection = 'row';

    if (small) {
      titleGap = '1.5rem';
    }
  }

  if (!icon) boxHeight = small ? '0rem' : '8rem';
  if ((icon && title && iconAlign !== 'start')) {
    boxHeight = '8rem';
    smallTopPad = '1rem';
  }

  return (
    <Box
      key={uniqid()}
      height={{ min: boxHeight }}
      width={small ? '100%' : desktopWidth}
      margin={{ vertical: verticalMargin }}
      pad={{ horizontal: rowIconTitle ? '1.25rem' : '0' }}
      align="center"
      justify={small ? 'center' : 'start'}
    >
      <Box
        align={iconAlign || 'center'}
        gap={iconAlign === 'start' ? titleGap : '1rem'}
        direction={small ? mobileDirection : direction}
      >
        {icon && !rowIconTitle && (
          <Box width="3.75rem" height="3.75rem">
            <LazyResponsiveImageLoader
              src={() => (small ? `${optimizeImageSrc(icon)}&w=350` : optimizeImageSrc(icon))}
              alt={`${title} icon`}
              fit="contain"
            />
          </Box>
        )}
        {icon && rowIconTitle && (
          <Box wrap={false} direction="row" width="100%" align="center" justify="start" gap="1.5rem">
            <Box width="3.25rem" height="3.25rem">
              <LazyResponsiveImageLoader
                src={() => (small ? `${optimizeImageSrc(icon)}&w=350` : optimizeImageSrc(icon))}
                alt={`${title} icon`}
                fit="contain"
              />
            </Box>
            <StyledSmallHeader
              color={titleColor || textColor}
              size={small ? '1.5rem' : '1.25rem'}
              weight={600}
              textAlign="start"
            >
              {title}
            </StyledSmallHeader>
          </Box>
        )}
        <Box
          width={(direction === 'row' && !small) ? '15rem' : innerBoxWidth}
          height="100%"
          justify="center"
          direction="column"
          gap="0.85rem"
        >
          {!rowIconTitle && (
            <Box
              width={(iconAlign === 'start' && !small) ? { max: '15rem' } : '100%'}
              justify={iconAlign || 'center'}
              align={iconAlign || 'center'}
              pad={{ top: small ? smallTopPad : topPad }}
            >
              <StyledSmallHeader
                color={titleColor || textColor}
                size={icon ? '1.25rem' : '1.5rem'}
                weight={600}
                textAlign={(direction === 'row' || iconAlign === 'start') ? 'start' : 'center'}
              >
                {title}
              </StyledSmallHeader>
            </Box>
          )}
          {text && (
            <Box justify="center" align="center">
              <Box width={(small || rowIconTitle) ? '100%' : '75%'}>
                <StyledTextContent color={textColor} textAlign={textAlign || 'center'}>
                  {text}
                </StyledTextContent>
              </Box>
            </Box>
          )}
          {list && Array.isArray(list) && (
            renderListText(small, false, list, textColor)
          )}
        </Box>
      </Box>
    </Box>
  );
};

const IconsScroller = ({
  small,
  header = null,
  headerColor = null,
  textColor,
  iconAlign = null,
  textAlign = null,
  desktopWidth,
  desktopImage = null,
  iconTitleColor = null,
  innerBoxBg = null,
  innerBoxShadow = null,
  layout,
  rowIconTitle = false,
  items,
  approach = null,
}) => (
  <Box overflow="hidden">
    <Box direction="column" gap="2.5rem">
      {header && (
        <ScrollerHeader header={header} color={headerColor || textColor} alignSelf="center" />
      )}
      {items && (small || !desktopImage) && (
        <StyledAnalysisCardWrapper
          boxShadow={innerBoxShadow}
          background={innerBoxBg || 'rgba(0,0,0,0)'}
          borderRadius="15px"
          margin={innerBoxShadow ? '0.5rem' : 'none'}
          pad={innerBoxShadow ? { horizontal: '1.75rem' } : '0rem'}
        >
          <Box wrap direction="row" justify="evenly">
            {items.map((item, i) => (
              renderIconText(
                small,
                i,
                iconAlign,
                textAlign,
                desktopWidth,
                item,
                textColor,
                iconTitleColor,
                layout,
                rowIconTitle,
              )
            ))}
          </Box>
        </StyledAnalysisCardWrapper>
      )}
      {desktopImage && !small && (
        <Box flex pad={{ horizontal: '5.5rem', vertical: '1.65rem' }}>
          <StyledSVG
            src={optimizeImageSrc(desktopImage)}
            width="100%"
            height="auto"
            title={`${header} icon`}
          />
        </Box>
      )}
      {approach && (
        <Box align="center" width="100%">
          <ApproachButton approach={approach} />
        </Box>
      )}
    </Box>
  </Box>
);

IconsScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  headerColor: PropTypes.string,
  layout: PropTypes.string.isRequired,
  rowIconTitle: PropTypes.bool,
  iconAlign: PropTypes.string,
  textAlign: PropTypes.string,
  desktopWidth: PropTypes.string.isRequired,
  desktopImage: PropTypes.string,
  iconTitleColor: PropTypes.string,
  innerBoxBg: PropTypes.string,
  innerBoxShadow: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    list: PropTypes.arrayOf(PropTypes.string.isRequired),
  })),
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default IconsScroller;
