import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Button, Text, Image,
} from 'grommet';

import { ApproachButton, ScrollerHeader } from './ContentSections';
import { StyledEmbedContainer, StyledCardImage, StyledCarousel } from './StyledContentSections';


export const LinkedInPostCard = ({
  small,
  boxShadow,
  hoverShadow,
  textColor,
  highlightColor,
  followers,
  postData: {
    postUrl,
    postText,
    hashtags,
    linkData: {
      title,
      image,
    },
  },
}) => (
  <StyledEmbedContainer
    href={postUrl}
    margin={small ? '0' : '1rem'}
    boxShadow={boxShadow}
    hoverShadow={hoverShadow}
    width={small ? '100%' : '20%'}
    minWidth={small ? '0' : '290px'}
    height="675px"
  >
    <Box
      direction="column"
      gap="1rem"
      height="100%"
      justify="between"
      round="0.625rem"
      overflow="hidden"
      background="white"
    >
      <Box
        direction="column"
        gap="1rem"
        width="100%"
        height="100%"
        pad={{ horizontal: '1rem', top: '1rem' }}
      >
        <Box direction="row" justify="start" align="start">
          <Box width="52px" gap="0.5rem" pad={{ right: '10px' }}>
            <Image
              fit="contain"
              src="/images/logo/logo-vertical-layout-color.svg"
              alt="Redflag AI logo"
              width="100%"
              height="auto"
            />
          </Box>
          <Box direction="column" gap="0.25rem">
            <Text size="16px" color={textColor} weight={600} textAlign="start">
              Redflag AI
            </Text>
            <Text size="12px" color="#666666" textAlign="start">
              {`${followers} followers`}
            </Text>
          </Box>
        </Box>
        <Box direction="column" gap="1rem">
          {postText.map((dt) => (
            <Text key={uniqid()} size={small ? '14px' : '16px'} color={textColor} textAlign="start">
              {dt}
            </Text>
          ))}
          <Box wrap direction="row">
            {hashtags.map((tag) => (
              <Box key={uniqid()} pad={{ right: '0.5rem' }}>
                <Text size={small ? '14px' : '16px'} color={highlightColor} textAlign="start">
                  #
                  {tag}
                </Text>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box direction="column" round={{ size: '0.625rem', corner: 'bottom' }} height="100%" justify={small ? 'start' : 'end'}>
        <StyledCardImage
          data-bg={image}
          background={{ image: small ? `url(${image}&w=1024)` : `url(${image})` }}
          overflow="hidden"
          width="100%"
          bgSize="115%"
          height="190px"
        />
        <Box
          direction="column"
          pad="1rem"
          background="#F0F6FF"
          round={{ size: '0.625rem', corner: 'bottom' }}
          height={small ? '98px' : '120px'}
          align="center"
        >
          <Text size={small ? '15px' : '17px'} color={textColor} weight={600} textAlign="start">
            {title}
          </Text>
        </Box>
      </Box>
    </Box>
  </StyledEmbedContainer>
);

LinkedInPostCard.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  boxShadow: PropTypes.string.isRequired,
  hoverShadow: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  followers: PropTypes.string.isRequired,
  postData: PropTypes.shape({
    postUrl: PropTypes.string.isRequired,
    postText: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    hashtags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    linkData: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const SocialMediaScroller = ({
  small,
  textColor,
  highlightColor,
  header,
  boxShadow,
  hoverShadow,
  linkedInPosts,
  followers,
  approach = null,
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);

  return (
    <Box overflow="hidden">
      <Box direction="column" gap="2rem">
        <ScrollerHeader header={header} color={textColor} />
        <Box flex wrap justify="center" align="center" direction={small ? 'column' : 'row'}>
          {!small && linkedInPosts && linkedInPosts.map((postData) => (
            <LinkedInPostCard
              key={uniqid()}
              small={small}
              postData={postData}
              boxShadow={boxShadow}
              hoverShadow={hoverShadow}
              textColor={textColor}
              highlightColor={highlightColor}
              followers={followers}
            />
          ))}
          {small && linkedInPosts && (
            <Box>
              <StyledCarousel
                emulateTouch
                isSmall={small}
                showArrows={false}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={activeSlide}
                onChange={(index) => setActiveSlide(index)}
                width="100%"
                height="610px"
                alignSelf="center"
                boxShadow={boxShadow}
              >
                {linkedInPosts.map((postData) => (
                  <LinkedInPostCard
                    key={uniqid()}
                    small={small}
                    postData={postData}
                    boxShadow={boxShadow}
                    hoverShadow={hoverShadow}
                    textColor={textColor}
                    highlightColor={highlightColor}
                    followers={followers}
                  />
                ))}
              </StyledCarousel>
              <Box direction="row" gap="0.75rem" justify="center">
                {linkedInPosts.map((_, i) => (
                  <Button key={uniqid()} onClick={() => setActiveSlide(i)}>
                    <Text size="3.5rem" color={i === activeSlide ? highlightColor : '#D0D0D0'}>
                      •
                    </Text>
                  </Button>
                ))}
              </Box>
            </Box>
          )}
        </Box>
        {approach && (
          <Box pad={{ top: '1rem' }} align="center">
            <ApproachButton approach={approach} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

SocialMediaScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  boxShadow: PropTypes.string.isRequired,
  hoverShadow: PropTypes.string.isRequired,
  followers: PropTypes.string.isRequired,
  linkedInPosts: PropTypes.arrayOf(PropTypes.shape({
    postUrl: PropTypes.string.isRequired,
    postText: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    hashtags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    linkData: PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired).isRequired,
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default SocialMediaScroller;
