'use client';

import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import { useParams } from 'next/navigation';

import { Box, Text } from 'grommet';
import { InlineWidget } from 'react-calendly';

import { useClientMediaQuery } from '@/lib/clientMediaQuery';
import { optimizeImageSrc } from '@/helpers/util';
import {
  Container, FullHeightBox, AppAnchor, AppButton,
} from '@/components/Control';
import {
  StyledMeetingsContainer, StyledFormContainer, StyledHeading, StyledList,
} from './StyledContactPage';


const renderListText = (small, listText, textColor) => (
  <StyledList color={textColor} size="1.1rem" textAlign={small ? 'center' : 'start'}>
    {listText.map((dt) => <li key={uniqid()}>{dt}</li>)}
  </StyledList>
);

const FormSection = ({
  small, title, titleColor, bodyText, textColor, formContainer,
}) => (
  <Box direction="column" gap="1.5rem" align="center" pad={{ horizontal: small ? 'var(--vw5p725)' : '0rem' }}>
    <Box flex direction="column" gap="1.5rem">
      <StyledHeading
        level={1}
        textAlign="center"
        color={titleColor}
        size={small ? '2.5rem' : '3rem'}
        weight={600}
      >
        {title}
      </StyledHeading>
      <Box direction="column" gap="0.5rem">
        {bodyText.map((dt) => {
          if (Array.isArray(dt)) {
            return (renderListText(small, dt, textColor));
          }

          return (
            <Text key={uniqid()} textAlign="center" color={textColor} size="1.1rem">
              {dt}
            </Text>
          );
        })}
      </Box>
    </Box>
    {formContainer}
  </Box>
);

FormSection.propTypes = {
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  bodyText: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  textColor: PropTypes.string.isRequired,
  formContainer: PropTypes.node.isRequired,
};

const ContactPage = ({ config = null }) => {
  const small = useClientMediaQuery('(max-width: 768px)');

  let useImageBg = false;

  React.useEffect(() => {
    if (config && config.formEmbed && !config.embedUrl) {
      const {
        bodyScriptSrc, region, portalId, formId, target,
      } = config.formEmbed;
      const script = document.createElement('script');
      script.src = bodyScriptSrc;
      document.body.appendChild(script);
      script.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region, portalId, formId, target,
          });
        }
      });
      return (() => {
        document.body.removeChild(script);
      });
    }

    if (config && config.meetingsEmbed && !config.embedUrl) {
      const script = document.createElement('script');
      script.src = config && config.meetingsEmbed.bodyScriptSrc;
      script.async = true;
      document.body.appendChild(script);
      return (() => {
        document.body.removeChild(script);
      });
    }

    return undefined;
  }, [config]);

  if (!config) return null;

  const {
    pageBg,
    header,
    headerColor,
    paragraph,
    textColor,
    meetingsEmbed,
    formEmbed,
    embedUrl,
    lowerHeader,
    lowerSectionBg,
    buttonLabel,
    buttonColor,
    buttonTextColor,
  } = config;

  if (pageBg.startsWith('https')) {
    useImageBg = true;
  }

  const selectFormContainer = () => {
    if (formEmbed && !embedUrl) {
      return <StyledFormContainer id={formEmbed.target.replace('#', '')} />;
    }

    if (config && meetingsEmbed && !embedUrl) {
      return (
        <StyledMeetingsContainer
          className={meetingsEmbed.containerClass}
          data-src={meetingsEmbed.containerDataSrc}
        />
      );
    }

    return (
      <Box width="100%" pad={{ bottom: '3.5rem' }}>
        <InlineWidget url={embedUrl} styles={{ height: small ? '785px' : '735px' }} />
      </Box>
    );
  };

  return (
    <FullHeightBox
      fill
      pad={{ top: '5.2rem' }}
      data-bg={useImageBg ? optimizeImageSrc(pageBg) : null}
      background={useImageBg ? { image: small ? `url(${optimizeImageSrc(pageBg)}&w=1024)` : `url(${optimizeImageSrc(pageBg)})` } : optimizeImageSrc(pageBg)}
    >
      <Container pad={{ top: '3.5rem' }}>
        <FormSection
          small={small}
          title={header}
          titleColor={headerColor}
          bodyText={paragraph}
          textColor={textColor}
          formContainer={selectFormContainer()}
        />
        <Box
          background={lowerSectionBg}
          direction="column"
          justify="center"
          gap="2rem"
          pad={{ horizontal: small ? 'var(--vw5p725)' : 'var(--vw7p725)', vertical: '4rem' }}
        >
          <StyledHeading
            level={2}
            textAlign="center"
            color={headerColor}
            size={small ? '2rem' : '2.25rem'}
            weight={600}
          >
            {lowerHeader}
          </StyledHeading>
          <Box alignSelf="center">
            <AppAnchor href="mailto:contact@redflagai.co" target="_blank">
              <AppButton
                overrideHover
                level="dynamicLarge"
                label={buttonLabel}
                alignSelf={small ? 'center' : 'start'}
                color={buttonTextColor}
                bgColor={buttonColor}
              />
            </AppAnchor>
          </Box>
        </Box>
      </Container>
    </FullHeightBox>
  );
};

ContactPage.propTypes = {
  config: PropTypes.shape({
    pageBg: PropTypes.string,
    header: PropTypes.string,
    headerColor: PropTypes.string,
    paragraph: PropTypes.arrayOf(PropTypes.any),
    textColor: PropTypes.string,
    meetingsEmbed: PropTypes.shape({
      bodyScriptSrc: PropTypes.string.isRequired,
      containerClass: PropTypes.string.isRequired,
      containerDataSrc: PropTypes.string.isRequired,
    }),
    formEmbed: PropTypes.shape({
      bodyScriptSrc: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired,
      portalId: PropTypes.string.isRequired,
      formId: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
    }),
    embedUrl: PropTypes.string,
    lowerHeader: PropTypes.string,
    lowerSectionBg: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
  }),
};

export default ContactPage;
