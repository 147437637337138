import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';

import {
  Box, Text, Heading, Image,
} from 'grommet';

import { PlaceholderLoader} from '@/components/Control';
import { optimizeImageSrc } from '@/helpers/util';
import { paths } from '@/components/configs';
import { ApproachButton, ScrollerHeader } from './ContentSections';
import { StyledResourcesAnchor, StyledTextContent } from './StyledContentSections';


const StyledArticleTitle = styled(Heading)`
  font-weight: 600;
`;

export const PostCard = ({
  small,
  post,
  boxShadow = null,
  hoverShadow = null,
  cardBg = null,
  actionText = 'Read now →',
  cardHeight = '22rem',
  cardWidth = '20rem',
  imageHeight = null,
  textColor,
  textHighlightColor,
}) => {
  const truncateText = (str) => ((str.length > 117) ? `${str.substring(0, 114)}...` : str);
  const truncateTitle = (str) => ((str.length > 65) ? `${str.substring(0, 62)}...` : str);
  const defaultImageHeight = small ? '15rem' : '11rem';

  return (
    <StyledResourcesAnchor
      isSmall={small}
      path={paths.blogPost.replace(':uid', post.slug)}
      boxShadow={boxShadow}
      hoverShadow={hoverShadow}
      cardBg={cardBg}
    >
      <Box
        direction="column"
        gap="1rem"
        height={cardHeight}
        width={cardWidth}
        justify="between"
      >
        <Box round={{ size: '8px' }} width="100%" height={imageHeight ?? defaultImageHeight} overflow="hidden">
          <Image
            src={optimizeImageSrc(post.metadata.image.imgix_url)}
            alt={`${post.title} image`}
            fit="cover"
          />
        </Box>
        <Box flex direction="column">
          <Box direction="column" gap="1.65rem">
            <StyledArticleTitle
              size={small ? '1.2rem' : '1.125rem'}
              level={3}
              color={textColor}
              margin="none"
            >
              {post.title}
            </StyledArticleTitle>
            <Text size="1rem" weight={600} color={textHighlightColor} textAlign="start">{actionText}</Text>
          </Box>
        </Box>
      </Box>
    </StyledResourcesAnchor>
  );
};

PostCard.propTypes = {
  small: PropTypes.bool.isRequired,
  post: PropTypes.objectOf(PropTypes.any).isRequired,
  boxShadow: PropTypes.string,
  hoverShadow: PropTypes.string,
  cardBg: PropTypes.string,
  cardWidth: PropTypes.string,
  cardHeight: PropTypes.string,
  actionText: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  textHighlightColor: PropTypes.string.isRequired,
};

const ResourcesScroller = ({
  small,
  textColor,
  header,
  headerColor = null,
  textHighlightColor,
  cardBg,
  boxShadow,
  hoverShadow,
  approach = null,
  approachText = null,
  articleUids = null,
  articles = null,
}) => {
  let displayPosts = null;

  if (articles && !articleUids) {
    const [trending, standard] = _.partition(articles, (dt) => dt.metadata.trending);

    if (trending && trending.length === 3) {
      displayPosts = trending;
    } else if (trending && trending.length > 3) {
      displayPosts = _.sampleSize(trending, 3);
    } else {
      displayPosts = trending.concat(_.sampleSize(standard, 3 - trending.length));
    }
  } else if (articles && articleUids) {
    displayPosts = _.filter(articles, ((dt) => articleUids.includes(dt.slug)));
  }

  const isUneven = displayPosts && displayPosts.length % 3 !== 0;

  return (
    <Box overflow="hidden">
      <Box direction="column" gap="2rem">
        <ScrollerHeader header={header} color={headerColor || textColor} />
        <Box direction="column">
          {displayPosts && displayPosts.length
            ? (
              <Box
                wrap={!small}
                direction={small ? 'column' : 'row'}
                justify={small ? 'center' : 'start'}
                align="start"
              >
                {displayPosts.map((post) => (
                  <Box key={post.slug} pad={{ horizontal: small ? '0' : '1rem', vertical: '1rem' }} width={small ? '100%' : '33%'}>
                    <PostCard
                      key={post.slug}
                      small={small}
                      post={post}
                      boxShadow={boxShadow}
                      hoverShadow={hoverShadow}
                      cardWidth="100%"
                      cardHeight={small ? '23.5rem' : '25rem'}
                      imageHeight={small ? '15rem' : '14rem'}
                      textColor={textColor}
                      textHighlightColor={textHighlightColor}
                    />
                  </Box>
                ))}
                {approach && isUneven && approachText && (
                  <Box pad={{ horizontal: small ? '0' : '1rem', vertical: '1rem' }} height={small ? '20rem' : '22.5rem'} width={small ? '100%' : '33%'}>
                    <Box
                      flex
                      background="#E4E4E4"
                      round="8px"
                      justify="center"
                      align="center"
                      direction="column"
                      gap="1rem"
                      pad={{ horizontal: '3%' }}
                    >
                      <StyledTextContent size={small ? '1.7rem' : '1.5rem'} weight={600} color={textColor} textAlign="center">
                        {approachText}
                      </StyledTextContent>
                      <Box align="center">
                        <ApproachButton approach={approach} />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : <PlaceholderLoader.PDF />}
        </Box>
        {approach && !isUneven && (
          <Box align="center">
            <ApproachButton approach={approach} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

ResourcesScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  textColor: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string,
  cardBg: PropTypes.string.isRequired,
  boxShadow: PropTypes.string.isRequired,
  hoverShadow: PropTypes.string.isRequired,
  textHighlightColor: PropTypes.string.isRequired,
  articleUids: PropTypes.arrayOf(PropTypes.string.isRequired),
  approachText: PropTypes.string,
  articles: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      articlecontent: PropTypes.string.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      readtime: PropTypes.string.isRequired,
      published: PropTypes.string.isRequired,
      trending: PropTypes.bool.isRequired,
      thankyoudisplay: PropTypes.bool.isRequired,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        imgix_url: PropTypes.string.isRequired,
      }),
    }).isRequired,
  })),
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default ResourcesScroller;
