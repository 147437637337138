import React from 'react';
import PropTypes from 'prop-types';

import { Box } from 'grommet';

import { paths } from '@/components/configs';
import { ApproachButton } from './ContentSections';
import {
  StyledIndustryCardAnchor,
  StyledFillerCard,
  StyledTextContent,
  StyledSmallHeader,
  StyledScrollerHeader,
  StyledCardImage,
} from './StyledContentSections';


const IndustriesScroller = ({
  small,
  industryCards,
  header,
  headerColor = null,
  textColor,
  textHighlight,
  approach = null,
  approachText = null,
}) => {
  const isUneven = industryCards.length % 3 !== 0;

  return (
    <Box flex direction="column" gap="2rem" justify="center" align="center">
      <Box width="100%" justify="center">
        <StyledScrollerHeader level={2} color={headerColor || textColor} textAlign="center" margin="none">
          {header}
        </StyledScrollerHeader>
      </Box>
      <Box wrap direction="row">
        {industryCards && industryCards.map(({
          industryKey, image, title, headline,
        }) => (
          <StyledIndustryCardAnchor
            revealContent
            isSmall={small}
            key={industryKey}
            path={paths.industries.replace(':uid', industryKey)}
            margin="1rem"
          >
            <Box height="100%" width="100%" direction="column" overflow="hidden" gap="0.5rem">
              <StyledCardImage
                data-bg={image}
                background={{ image: small ? `url(${image}&w=768)` : `url(${image})` }}
                overflow="hidden"
                height="100%"
                width="100%"
                round="5px"
              >
                <Box
                  direction="column"
                  pad="1.5rem"
                  gap="1rem"
                  className="overlay"
                  height="100%"n
                  width="100%"
                  justify="center"
                  align="center"
                >
                  <StyledTextContent weight={600} color="white">
                    {headline}
                  </StyledTextContent>
                  <Box width="100%" pad={{ right: '0.5rem' }}>
                    <StyledTextContent size={small ? '1rem' : '0.9rem'} color="white" style={{ alignSelf: 'end' }}>
                      Learn More →
                    </StyledTextContent>
                  </Box>
                </Box>
              </StyledCardImage>
              <Box direction="column" gap="0.85rem" pad={{ bottom: '6px' }}>
                <StyledSmallHeader
                  uppercase
                  adjustSize
                  className="underline"
                  underlineColor={textHighlight}
                  weight={600}
                  color={textColor}
                  style={{ letterSpacing: '2px' }}
                >
                  {title}
                </StyledSmallHeader>
              </Box>
            </Box>
          </StyledIndustryCardAnchor>
        ))}
        {approach && isUneven && approachText && (
          <StyledFillerCard
            background="#E4E4E4"
            margin="1rem"
            round="5px"
            justify="center"
            align="center"
            direction="column"
            gap="1rem"
            pad={{ horizontal: '5%' }}
          >
            <StyledTextContent size={small ? '1.7rem' : '1.5rem'} weight={600} color={textColor} textAlign="center">
              {approachText}
            </StyledTextContent>
            <Box align="center">
              <ApproachButton approach={approach} />
            </Box>
          </StyledFillerCard>
        )}
      </Box>
      {approach && !isUneven && (
        <Box align="center">
          <ApproachButton approach={approach} />
        </Box>
      )}
    </Box>
  );
};

IndustriesScroller.propTypes = {
  small: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  headerColor: PropTypes.string,
  approachText: PropTypes.string,
  industryCards: PropTypes.arrayOf(PropTypes.shape({
    industryKey: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
  })).isRequired,
  textColor: PropTypes.string.isRequired,
  textHighlight: PropTypes.string.isRequired,
  approach: PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
  }),
};

export default IndustriesScroller;
